import React from "react";
import Logo from "./Logo";
import Toggle from "../Buttons/Toggle";

interface FooterProps {
  title: string | null;
  toggleLanguage: () => void;
  language: string;
}

const Footer: React.FC<FooterProps> = ({ title, toggleLanguage, language }) => {
  return (
    <footer>
      <a href="#top" id="logo">
        <Logo />
        <span>{title}</span>
      </a>
      <div id="languageToggle">
        <Toggle
          beforeText="EN"
          afterText="PL"
          isOn={language === "pl"}
          onToggle={toggleLanguage}
        />
      </div>
    </footer>
  );
};

export default Footer;
