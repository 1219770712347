import React, { useState } from "react";
import Section from "../Section";
import { useTranslation } from "react-i18next";
import RsvpForm from "../Forms/RsvpForm";
import SecurityQuestion from "../Forms/SecurityQuestion";

const Rsvp: React.FC = () => {
  const { t } = useTranslation();
  const [hasAccess, setHasAccess] = useState(false);
  const [error, setError] = useState("");

  const featureToggle = process.env.REACT_APP_SECURITY_QUESTION_ENABLED;

  const handleAccessGranted = () => {
    setHasAccess(true);
    setError("");
  };

  const handleAccessDenied = (errorMsg: string) => {
    setError(errorMsg);
  };

  const sectionStyle = {
    backgroundImage: "url('/images/background-repeat.svg')",
    backgroundRepeat: "round",
  };

  const content = (
    <div className="section-full-width section-text" key="rsvp_form">
      <h2 className="section-title">{t("section.rsvp.heading")}</h2>
      {hasAccess ? (
        <RsvpForm />
      ) : featureToggle ? (
        <SecurityQuestion
          onAccessGranted={handleAccessGranted}
          onAccessDenied={handleAccessDenied}
        />
      ) : (
        <h3 className="section-title">{t("section.rsvp.noAccessMessage")}</h3>
      )}
      {error && <p className="rsvp-form-errors visible">{error}</p>}
    </div>
  );

  return <Section id="rsvp" content={content} style={sectionStyle} />;
};

export default Rsvp;
